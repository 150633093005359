.pulse-modal {
  .pulse-modal-body-content-md {
    .pulse-modal-body {
      min-height: 34vh;
    }
  }
}

.pulse-modal-body .mc-form-row {
  background-color: #fff;
}

.pulse-modal-body .mc-form-row .col {
  display: flex;
  gap: 0.25rem;
  flex-wrap: nowrap;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
}

.pulse-modal-content-xl > .pulse-modal-body {
  min-height: 345px !important;
}

.pulse-modal-body{
  padding: 1rem;
}

.pulse-modal-body .col{
  padding: 0;
}

.pulse-modal-body .row {
  //background-color: #ebeef2;
}

.modal-background {
 //margin: 0.65rem;
}

.pulse-modal-header {
  //padding: 0.7rem;
  padding: 1rem !important;
}

.pulse-modal-footer {
  padding: 1rem;
  //border-top: 1px solid #eee;
}

.pulse-modal-title {
  font-size: 1.3rem;
}