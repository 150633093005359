@import "../core/colours";

.fc-daygrid-event {
  border: none !important;
  border-radius: 0 !important;
  background-color: #fff !important;
}

.fc-daygrid-day-number {
  color: #1a2a35;
}

.fc-theme-standard th {
  background-color: #f3f4f5;
}

.fc-daygrid-day.fc-day-today {
  background-color: #ffffff !important;
}

.fc-day-today {
  .fc-daygrid-day-number {
    color: white;
    background-color: #2A5A7B;
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fc-daygrid-event {
  white-space: wrap !important;
}

.fc-highlight {
  background-color: #fff !important;
}

.calendar-event {
  background-color: #eef2fa;
  color: #2E5AAC;
  border: 2px solid #2E5AAC;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;

  // **** ABSENCE TYPES ****
  &.holiday {
    // No override, use default
  }

  &.sickpaid {
    border-color: #2A8A41;
    background-color: #edf9f0;
    color: #2A8A41;
  }

  &.sickpaid:hover {
    border-color: #7fb98d;
  }

  &.sickunpaid {
    border-color: #876D17;
    background-color: #fffbf2;
    color: #876D17;
  }

  &.sickunpaid:hover {
    border-color: #b7a774;
  }

  &.unauthorised {
    border-color: #C70A0A;
    background-color: #feefef;
    color: #C70A0A;
  }

  &.unauthorised:hover {
    border-color: #dd6c6c;
  }

  // **** PENDING REQUEST TYPES ****
  &.approved {
    border-color: #2E5AAC;
    background-color: #eef2fa;
    color: #2E5AAC;
  }

  &.approved:hover {
    border-color: #829ccd;
  }

  &.pendingapproval {
    border-color: #876D17;
    background-color: #fffbf2;
    color: #876D17;
  }

  &.pendingapproval:hover {
    border-color: #b7a774;
  }

  &.rejected {
    border-color: #C70A0A;
    background-color: #feefef;
    color: #C70A0A;
  }

  &.rejected:hover {
    border-color: #dd6c6c;
  }

  // **** STATE OVERRIDES ****
  &:hover {
    border-color: $c2;
  }
}