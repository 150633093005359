@import "colours";
@import "typography";
@import "variables";

.pulse-toast-container {
  width: 500px;
}

.toast-icon {
  display: grid;
  align-items: center;
  justify-content: center;
}

.pulse-toast {
  background: $mc-blue-01;
  color: $mc-white-01;
  border-radius: 0;
  min-height: 40px;
  padding: 5px 8px;
  font-family: $body-font;
  font-size: 0.8em;

  button {
    opacity: 1;

    &:hover {
      transition: $animation-duration ease-in-out opacity;
      opacity: .3;
    }
  }
  svg {
    color: $mc-white-01
  }
}

.pulse-toast.error-toast {
  background-color: $mc-red-01;
}

.pulse-toast.success-toast {
  background-color: $mc-yellow-01;
  color: $mc-blue-01;

  svg {
    color: $mc-blue-01;
  }
}

.pulse-toast-body {
  margin: 5px 0;
  padding: 0 5px;
}

@media only screen and (max-width: 600px) {
  .pulse-toast-container {
    width: 400px;
  }
}

@media only screen and (max-width: 576px) {
  .pulse-toast-container {
    width: 300px;
  }
}