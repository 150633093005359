@import "colours";
@import "typography";

.tooltip-override-xs,
.tooltip-override-sm,
.tooltip-override-md,
.tooltip-override-lg {
  text-align: center !important;
  overflow: visible;
  border-radius: 0 !important;
}

.tooltip-override-xs.light,
.tooltip-override-sm.light,
.tooltip-override-md.light,
.tooltip-override-lg.light {
  font-family: $body-font;
  background: $mc-white-01 !important;
  color: $mc-blue-01;
}

.tooltip-override-xs.dark,
.tooltip-override-sm.dark,
.tooltip-override-md.dark,
.tooltip-override-lg.dark {
  font-family: $body-font;
  //background: $mc-dark-blue-transparent !important;
}

.tooltip-override-xs {
  width: 100px;
}
.tooltip-override-sm {
  width: 150px !important;
}
.tooltip-override-md {
  width: 200px;
}
.tooltip-override-lg {
  width: 250px;
}

.__react_component_tooltip {
  transition: none !important;
  font-weight: 400 !important;
}

.tooltip-override-xs.light, .tooltip-override-sm.light, .tooltip-override-md.light, .tooltip-override-lg.light {
  background-color: #222 !important;
  color: #fff !important;
  font-weight: 400 !important;
}