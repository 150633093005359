@import "animations";
@import "colours";
@import "typography";
@import "variables";

.sidebar-bg.bg-loaded {
  opacity: 0.6;
}

.main-menu-overlay {
  background-color: black;
}

.sidebar-bg {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  -webkit-transition: opacity .25s;
  -o-transition: opacity .25s;
  transition: opacity .25s;
}

.fadeInMenu {
  animation-name: fadeInMenu;
  animation-duration: $animation-duration;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.sidebar-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none!important;
  -webkit-backface-visibility: hidden;
}

.sidebar-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.sidebar-content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 260px;
  background-color: $mc-white-01;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  -webkit-transform: translateX(-270px);
  -ms-transform: translateX(-270px);
  transform: translateX(-270px);
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
  box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
}

.off-canvas-left {
  //transform: translate(0,0);
  //opacity: 1;
}

.sidebar-close {
  cursor: pointer;
  outline: none;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: fixed;
  top: 0;
  right: 0;
  text-decoration: none;
  text-align: center;
  padding: 0;
  mix-blend-mode: difference;
  margin: 0!important;
  color: #fff;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  min-height: 0;
  width: 40px;
  -webkit-backface-visibility: hidden;
  height: 40px;
  line-height: 40px;
  -webkit-transition: opacity .6s , -webkit-transform .6s;
  transition: opacity .6s , -webkit-transform .6s;
  -o-transition: opacity .6s , transform .6s;
  transition: opacity .6s , transform .6s;
  transition: opacity .6s , transform .6s , -webkit-transform .6s;
  -webkit-transform: translateY(25%) translateZ(1px);
  transform: translateY(25%) translateZ(1px);
}

@keyframes fadeInMenu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

.tab {
  background-color: $mc-white-01;
  color: $mc-blue-01;
  border: none;
  cursor: pointer;
  font-size: 0.85em;
  width: 50%;
  height: auto;
  display: grid;
  justify-content: center;
  align-items: center;

  border-bottom: 3px solid transparent;

  &:hover {
    background-color: $mc-white-hover;
    border-bottom: 3px solid $mc-yellow-01;
    transition: all ease-in-out $animation-duration;
  }
}
.tab-content-outer {
  padding: 15px 5px;
  background-color: $mc-white-01;
  height: 550px;
}

.tab p {
  padding: 15px 10px;
  font-size: 0.9em;
  font-weight: 400;
}

.tab.active {
  background-color: $mc-grey-02;
  color: $mc-blue-01;
  border-bottom: 3px solid $mc-blue-01;
}

.add-tab-button {
  background: none;
  border: none;
}

.tab-content-inner-fixed {
  height: 480px;
}

.tab-content-inner {
  height: auto;
  max-height: 480px;
}

.tab-content-inner,
.tab-content-inner-donut-charts {
  overflow-y: auto;
  overflow-x: hidden;
}

.tab-content-inner-donut-charts {
  height: auto;
  padding: 30px 20px;
}

.nav-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  padding: 0;
  list-style-type: none;
}

.nav-slide {
  height: auto;
  padding-top: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
}

.sidebar-nav {
  align-items: center;
  position: relative;
  width: 100%;
}

.sidebar-list-item-no-link,
.sidebar-list-item {
  text-decoration: none !important;
  border-bottom: 1px solid $mc-grey-02 !important;
  list-style: none;
  margin: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-shrink: 0;
}

.sidebar-list-item {
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer !important;
    background-color: $mc-grey-02;
    transition: background-color ease-in-out $animation-duration;

    a {
      color: $mc-dark-blue-hover !important;
      transition: color ease-in-out $animation-duration;
    }
  }
}

.sidebar-list-item {
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer !important;
    background-color: $mc-grey-02;
    transition: background-color ease-in-out $animation-duration;

    a {
      color: $mc-dark-blue-hover !important;
      transition: color ease-in-out $animation-duration;
    }
  }
}

.active-route {
  background-color: $mc-yellow-01;
  color: $mc-white-01 !important;

  &:hover {
    cursor: pointer !important;
    background-color: $mc-yellow-hover;
    transition: background-color ease-in-out $animation-duration;

    p, a {
      color: $mc-blue-01 !important;
      transition: color ease-in-out $animation-duration;
    }
  }
}

.subsystem-group-item,
.group-item {
  font-weight: 400;
}

.group-item {
  font-size: 0.8em;
  padding: 15px 0 15px 20px;
  color: $mc-grey-03 !important;
}

.subsystem-group-item {
  font-size: 0.7em !important;
  padding: 15px 0 15px 40px !important;
  color: $mc-grey-03 !important;
}
