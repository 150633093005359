@import "colours";

.mc-form-heading {
  color:#7b8185;
  padding: 1rem;
  margin-top: 1.5rem;
}

.mc-form-heading h5 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #282C2F;
}

.mc-form-row-optional,
.mc-form-row {
  padding: 15px 25px 15px 17px;
  background-color: #ebeef2;
}

.pulse-modal-body {
  .mc-form-row-optional,
  .mc-form-row {
  //background-color: #fff;
}
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
  background-color: #fff;
}
}

.row-modifier {
  .row {
  background-color: #fff !important;
}
}

.readonly-warning {
  //background-color: $mc-yellow-01;
  //color: $mc-blue-01;
  background-color: #ffc107;
  color:#212529;
}

.row-splitter {
  border-bottom: 1px solid $mc-blue-01;
  margin: 15px;
}

.status-info {
  padding: 5px;
  min-width: 300px;
  background-color: $mc-white-01;

  &:nth-child(odd){
    background-color: $mc-grey-02;
  }
}
