@import "variables";
@import "colours";
@import "typography";

.search-bar {
  width: 100%;
  border: 1px solid transparent;
  min-height: 42px !important;
}

.select-options__control:not(.select-options__control--menu-is-open) {
  //border-color: transparent !important;
  border: 1px solid #ebeef2;
}

.select-options__control[class*="select-options__control--menu-is-open"] {
  border-color: $mc-white-01 !important;
  background-color: $mc-white-01 !important;
  transition: $animation-duration ease;
}

.select-options__menu-portal {
  z-index: 20 !important;
}

.select-options__control {
  display: block;
  width: 100%;
  padding: 0 16px 0 0;
  background-color: $mc-white-01 !important;
  color: $mc-blue-01 !important;
  border-radius: 0 !important;
  outline: none;
  font-family: $body-font;
  font-size: 16px !important;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  min-height: 42px !important;
  height: 42px !important;
}

.select-options__control:hover {
  cursor: pointer !important;
}

.select-options__placeholder {
  margin-left: 0;
  margin-right: 0;
  padding: 9px 15px;
}

.select-options__input,
.select-options__single-value {
  color: $mc-blue-01 !important;
  margin-left: 0;
  margin-right: 0;
  padding: 9px 15px;
}

.select-options__indicator-separator {
  display: none !important;
}

.select-options__menu {
  top: 85% !important;
  background-color: $mc-white-01 !important;
  border-radius: 0 !important;
}

.select-options__menu-notice--no-options,
.select-options__option--is-focused,
.select-options__option{
  color: $mc-blue-01 !important;
  font-size: 0.9em !important;
  font-family: $body-font;
}

.select-options__option--is-selected {
  color: $mc-white-01 !important;
}


.select-options__option--is-focused {
  background-color: $mc-white-01 !important;
  transition: $animation-duration ease;
  cursor: pointer;
}

.select-options__option--is-focused:hover {
  background-color: darken($mc-white-01, 20%) !important;
  transition: $animation-duration ease;
  cursor: pointer;
}

.select-options__option--is-selected {
  background-color: $mc-blue-01 !important;
  transition: $animation-duration ease;
}

.select-options__option--is-selected:hover {
  background-color: lighten($mc-blue-01, 10%) !important;
  transition: $animation-duration ease;
}

.select-options__indicator svg {
  color: $mc-blue-01;
}

.select-options__control {
  .select-options__indicator svg {
    animation-name: rotate0;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
  }
}

.select-options__control--menu-is-open {
  .select-options__indicator svg {
    animation-name: rotate180;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
  }
}

.select-options__option {
  padding: 8px 25px !important;
}

.select-options__option:not(:first-child) {
  border-top: 1px solid $mc-dark-blue-transparent;
}

.select-options__value-container {
  padding: 2px 8px 2px 0 !important;
}


.select-options__multi-value__remove{
  border-radius: 0 !important;
}

.select-options__multi-value__remove:hover{
  background-color: darken($mc-red-01, 10%) !important;
  transition: $animation-duration ease;

}

.select-options__multi-value__remove,
.select-options__multi-value__label,
.select-options__multi-value {
  color: $mc-white-01 !important;
}

.select-options__multi-value {
  background-color: $mc-blue-01 !important;
  border-radius: 0 !important;
}

.select-options__multi-value:hover {
  background-color: darken($mc-dark-blue-hover, 10%) !important;
  transition: $animation-duration ease;
}
