@import "typography";

.pulse-table-header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.pulse-table-wrapper {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.pulse-table-header-item {
  font-family: $header-font;
  font-size: 0.95rem;
  color: #858C91;
  font-weight: 400;
}

.pulse-table-row-item {
  font-family: $body-font;
  font-size: 1rem;
}

.pulse-table-row {
  background-color: #fff;
  border-bottom: 1px solid #eee;

  &:nth-child(2n+1) {
    background-color: #fff;
  }

  &:last-child {
    border: none;
  }
}

.fc-media-screen {
  margin-left: 1rem;
  margin-right: 1rem;
}

.fc-theme-standard td {
  border: 1px solid #DADCE0 !important;
}

.fc-theme-standard th {
  border-top: 1px solid #DADCE0 !important;
  border-left: 1px solid #DADCE0 !important;
  border-right: 1px solid #DADCE0 !important;
}

a.fc-col-header-cell-cushion {
  color: #282C2F !important;
  font-weight: 500 !important;
}

.fc-daygrid-day-number {
  font-weight: 400 !important;
}