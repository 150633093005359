@import "variables";

@keyframes bounceAnimateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animateSidebarIn {
  0% {
    transform: translate(-270,0);
  }

  100% {
    transform: translate(0,0);
  }
}

.animateSidebarIn {
  animation-name: animateSidebarIn;
}

.fadeIn {
  animation-name: fadeInOpacity;
}

.fadeOut {
  animation-name: fadeOutOpacity;
}

.fadeOut,
.fadeIn,
.animateSidebarIn,
.animateHeightOut,
.animateHeightIn {
  animation-duration: $animation-duration;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
