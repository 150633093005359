@import "../core/colours";

section.top-bar {
    background-color: $c10;
    color: $c10-content;
    height: 3.5rem;
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (min-width: 1024px) {
        background-color: $c1;
        color: $c1-content;
    }

    .left {
        display: flex;
        align-items: center;
        gap: 0.75rem;
    }

    .middle {
        display: none;

        @media (min-width: 1024px) {
            display: block;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0;
        padding-left: 0.5rem;

        @media (min-width: 1024px) {
            gap: 0.5rem;
        }
    }

    .hamburger-wrapper {
        @media (min-width: 1024px) {
            display: none;
        }

        button {
            background: none;
            color: $c10-content;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.top-bar-title {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    h1 {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin: 0;
        padding: 0;

        @media (min-width: 1024px) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    .subtitle {
        display: none;
        color: $c3;
        margin: 0;
        padding: 0;

        @media (min-width: 1024px) {
            display: block;
        }
    }
}

.top-level-page {
    --nav-collapsed-width: 6.5rem;
    --nav-expanded-width: 17rem;
    --nav-transition-speed: 150ms;
    height: 100%;
    display: flex;
    flex-direction: column;

    .page-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
            padding-left: var(--nav-collapsed-width);
        }
    }
}

.top-bar {
    transition-property: height;
    transition-duration: var(--nav-transition-speed);

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &.top-bar-btn {
        display: none;
        gap: 0.5rem;

        /*color: $c10-content;
        font-size: 0.9rem;
        height: 100%;
        align-items: center;
        text-align: center;
        padding: 0.1rem 0.25rem;*/

        @media (min-width: 1024px) {
            display: flex;
            background-color: $c1;
            color: $c1-content;
            align-items: center;
            border-radius: 0.5rem;
            border: 2px solid $c4;
            padding: 0.5rem 0.75rem;
            font-weight: 500;
            font-size: 1rem;
            height: auto;
        }

        &:hover {
            background-color: $c10-hover;

            @media (min-width: 1024px) {
                background-color: $c1-hover;
            }
        }
    }

    &.top-bar-btn-primary {
        background-color: $c2;
        color: $c2-content;
        border-color: $c2;

        &:hover {
            background-color: $c2-hover;
            border-color: $c2-hover;
        }
    }

    &.top-bar-btn-icon {
    }

    &.top-bar-btn-content {
        /*display: none;*/

        @media (min-width: 1024px) {
            display: block;
        }
    }

    /** Naughty override for breadcrumbs to only render last few on mobile */
    .breadcrumb {
        li {
            display: none;

            &:nth-last-child(-n+3) {
                display: block;
            }
        }

        @media (min-width: 1024px) {
            li {
                display: block;
            }
        }
    }
}
